/* ------------------------------------------------------------------------------
 *
 *  # Components
 *
 *  Components import. Ordering matters. See _config.scss for more options
 *
 * ---------------------------------------------------------------------------- */


// Core
// ------------------------------

// Import custom template config
@import "../../../config";
@import "../../../utils/ll-functions";
@import "../../../utils/ll-mixins";

// Core variables and mixins
@import "../../../_bootstrap/functions";
@import "../../../_bootstrap/variables";
@import "../../../_bootstrap/mixins";

// Import template's variables
@import "../variables/variables-core";
@import "../variables/variables-custom";
@import "../../../bootstrap_limitless/maps";
@import "../../../bootstrap_limitless/mixins";
@import "../../../bootstrap_limitless/utilities";


// Forms
// ------------------------------

// Select2
@import "../../../vendor/forms/select2";

// Multiselect
@import "../../../vendor/forms/multiselect";

// Autocomplete
@import "../../../vendor/forms/autocomplete";

// Validation
@import "../../../vendor/forms/validation";

// Tokenfield
@import "../../../vendor/forms/tokenfield";

// Dual listboxes
@import "../../../vendor/forms/dual-listbox";

// Wizard
@import "../../../vendor/forms/wizard";


// Text editors
// ------------------------------

// Quill
@import "../../../vendor/forms/editors/quill";

// CKEditor
@import "../../../vendor/forms/editors/ckeditor";

// Trumbowyg
@import "../../../vendor/forms/editors/trumbowyg";

// Ace
@import "../../../vendor/forms/editors/ace";


// Pickers
// ------------------------------

// Daterange picker
@import "../../../vendor/pickers/daterange";

// Date picker
@import "../../../vendor/pickers/datepicker";

// Color picker
@import "../../../vendor/pickers/spectrum";


// File uploaders
// ------------------------------

// Plupload
@import "../../../vendor/forms/uploaders/plupload";

// File input
@import "../../../vendor/forms/uploaders/file-input";

// Dropzone
@import "../../../vendor/forms/uploaders/dropzone";


// Notifications
// ------------------------------

// Noty notifications
@import "../../../vendor/notifications/noty";

// Sweet Alerts
@import "../../../vendor/notifications/sweet-alerts";


// Sliders
// ------------------------------

// NoUI slider
@import "../../../vendor/sliders/noui-slider";

// Ion range slider
@import "../../../vendor/sliders/ion-range-slider";


// UI components
// ------------------------------

// Prism - syntax highlighter
@import "../../../vendor/ui/prism";

// Dragula
@import "../../../vendor/ui/dragula";

// Headroom
@import "../../../vendor/ui/headroom";



// Misc components
// ------------------------------

// Floating action buttons
@import "../../../vendor/misc/fab";

// Fancytree
@import "../../../vendor/misc/fancytree";

// Full calendar
@import "../../../vendor/misc/fullcalendar";

// Image cropper
@import "../../../vendor/misc/image-cropper";

// Lightbox plugin
@import "../../../vendor/misc/glightbox";


// Tables
// ------------------------------

// Grid.js tables
@import "../../../vendor/tables/gridjs";


// Datatables
// ------------------------------

// Core
@import "../../../vendor/tables/datatables/datatables";

// Datatables - columns reorder
@import "../../../vendor/tables/datatables/datatable-columns-reorder";

// Datatables - rows reorder
@import "../../../vendor/tables/datatables/datatable-rows-reorder";

// Datatables - fixed columns
@import "../../../vendor/tables/datatables/datatable-fixed-columns";

// Datatables - autofill
@import "../../../vendor/tables/datatables/datatable-autofill";

// Datatables - select
@import "../../../vendor/tables/datatables/datatable-select";

// Datatables - buttons
@import "../../../vendor/tables/datatables/datatable-buttons";

// Datatables - key table
@import "../../../vendor/tables/datatables/datatable-keytable";

// Datatables - scroller
@import "../../../vendor/tables/datatables/datatable-scroller";

// Datatables - responsive
@import "../../../vendor/tables/datatables/datatable-responsive";


// Maps
// ------------------------------

// All maps
@import "../../../shared/maps/maps";

// ECharts maps
@import "../../../shared/maps/echarts-maps";

// Leaflet
@import "../../../shared/maps/leaflet-maps";


// Charts
// ------------------------------

// Charts base
@import "../../../shared/charts/charts";

// C3 chart library
@import "../../../shared/charts/c3";

// D3 chart library
@import "../../../shared/charts/d3";


// Page kits
// ------------------------------

// Blog
@import "../../../shared/pages/blog";

// Inbox
@import "../../../shared/pages/inbox";

// Profile
@import "../../../shared/pages/profile";

// Login
@import "../../../shared/pages/login";

// Timelines
@import "../../../shared/pages/timelines";

// Chats
@import "../../../shared/pages/chats";


// Other components
// ------------------------------

// Ribbons
@import "../../../shared/content/ribbon";

// Helpers
@import "../../../utils/helpers";

// Plugins demo, remove in real project
@import "../../../vendor/demo";
