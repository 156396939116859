/* ------------------------------------------------------------------------------
 *
 *  # Global configuration
 *
 *  Here you can change main theme, enable or disable certain components and
 *  optional styles. This allows you to include only components that you need.
 *
 *  'true'  - enables component and includes it to main CSS file.
 *  'false' - disables component and excludes it from main CSS file.
 *
 * ---------------------------------------------------------------------------- */


//
// Core settings
// ------------------------------
//

//** Icon sets: 'Phosphor', 'icomoon', 'material-icons', 'Font Awesome 5 Free'
$icon-font-family:   'Phosphor' !default;
$icon-font-size:     1.25rem !default;
$icon-font-size-lg:  $icon-font-size * 1.2 !default;
$icon-font-size-sm:  $icon-font-size * .8 !default;


//
// Layout
// ------------------------------
//

//** Sidebar navigation types
$enable-sidebar-nav-icons-reverse:    true !default;
$enable-sidebar-nav-bordered:         true !default;

//** Boxed layouts
$enable-boxed:                        true !default;


//
// Components
// ------------------------------
//

//** Forms
$enable-select2:                      true !default;
$enable-multiselect:                  true !default;
$enable-autocomplete:                 true !default;
$enable-passy:                        true !default;
$enable-validation:                   true !default;
$enable-wizard:                       true !default;
$enable-dual-listbox:                 true !default;
$enable-tokenfield:                   true !default;


//** Editors
$enable-quill:                        true !default;
$enable-ckeditor:                     true !default;
$enable-trumbowyg:                    true !default;
$enable-ace:                          true !default;


//** Pickers
$enable-daterange:                    true !default;
$enable-datepicker:                   true !default;
$enable-spectrum:                     true !default;


//** File uploaders
$enable-plupload:                     true !default;
$enable-fileinput:                    true !default;
$enable-dropzone:                     true !default;


//** Notifications
$enable-noty:                         true !default;
$enable-sweetalert:                   true !default;


//** Sliders
$enable-noui:                         true !default;
$enable-ion:                          true !default;


//** Charts
$enable-charts:                       true !default;
$enable-d3:                           true !default;
$enable-c3:                           true !default;


//** Maps
$enable-maps:                         true !default;
$enable-echarts-maps:                 true !default;
$enable-leaflet-maps:                 true !default;


//** Tables
$enable-datatable:                    true !default;
$enable-datatable-select:             true !default;
$enable-datatable-scroller:           true !default;
$enable-datatable-row-reorder:        true !default;
$enable-datatable-responsive:         true !default;
$enable-datatable-keytable:           true !default;
$enable-datatable-fixed-columns:      true !default;
$enable-datatable-colreorder:         true !default;
$enable-datatable-buttons:            true !default;
$enable-datatable-autofill:           true !default;


//** Page kits
$enable-blog:                         true !default;
$enable-timeline:                     true !default;
$enable-login:                        true !default;
$enable-error:                        true !default;
$enable-profile:                      true !default;
$enable-tasks:                        true !default;
$enable-inbox:                        true !default;
$enable-chat:                         true !default;


//** Misc
$enable-fab:                          true !default;
$enable-glightbox:                    true !default;
$enable-fancytree:                    true !default;
$enable-fullcalendar:                 true !default;
$enable-image-cropper:                true !default;
$enable-dragula:                      true !default;
$enable-headroom:                     true !default;
$enable-prism:                        true !default;
$enable-demo:                         true !default;
$enable-ribbon:                       true !default;
